// import BlockContent from '../components/block-content'
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import LayoutContainer from "../components/layout-container";
// import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import localize from "../components/localize";
// import PeopleGrid from '../components/people-grid'
import SEO from "../components/seo";
import SeoContext from "../containers/SeoContext";
import Layout from "../containers/layout";
import { imageUrlFor } from "../lib/image-url";
import { responsiveTitle1 } from "../styles/typography.module.css";
import { backLink } from './portfolio.module.css';
import { graphql, Link } from "gatsby";
// import InfiniteScroll from "react-infinite-scroll-component"
import Img from "gatsby-image";
import React, { useState, useCallback } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import { jsx } from "theme-ui";


function processGallery(gallery) {
  return gallery.map((item, key) => {
    // console.log("processGallery", item)
    return {
      src: item.asset.fixed.srcWebp,
      height: item.asset.fixed.height,
      width: item.asset.fixed.width,
      sizes: item.asset.fixed.sizes,
      srcSet: item.asset.fixed.srcSetWebp,
      alt: item.alt
    }
  })
}

function processPreviewGallery(gallery) {
  return gallery.map((item, key) => {
    console.log("processPreviewGallery", item)
    let width = 1
    let height = 1
    // console.log("item.asset.fluid.aspectRatio", item.asset.fluid.aspectRatio)
    // switch (item.asset.fluid.aspectRatio) {
    //   case "0.6666666666666666":
    //     console.log("3/2")
    //     // 3/2
    //     width = 4
    //     height = 3
    //     break
    //   // 4:3 Aspect Ratio (divide 3 by 4 = 0.75)
    //   case "0.75":
    //     console.log("4/3")
    //     width = 4
    //     height = 3
    //     break
    //   /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    //   /* 1:1 Aspect Ratio */
    //   /* 1.33:1 Aspect Ratio */
    //   default:
    //     if (item.asset.fluid.aspectRatio > 0.5) {
    //       width = 4
    //       height = 3
    //     } else if (item.asset.fluid.aspectRatio != 1) {
    //       width = 4
    //       height = 3
    //     }
    //     break
    // }

    if (item.asset.fixed.aspectRatio > 1) {
      width = 1
      height = item.asset.fixed.aspectRatio
    } else if (item.asset.fixed.aspectRatio < 1) {
      width = item.asset.fixed.aspectRatio
      height = 1
    }
    console.log(height, width)
    console.log("item.asset.fluid", item.asset.fluid)
    return {
      src: item.asset.fluid.srcWebp,
      height: height,
      width: width,
      sizes: item.asset.fluid.sizes,
      srcSet: item.asset.fluid.srcSetWebp
    }
  })
}

const PortfolioTemplate = ({data, errors, pageContext}) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  console.log("DATA", data)

  const openLightbox = useCallback((event, {photo, index}) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const portfolio = data?.portfolio
  const site = data?.site
  const gallery = data?.portfolio?.gallery
  // const locale = pageContext?.locale
  // const altLang = pageContext?.altLang
  // console.log('DATA', portfolio)
  const {locale, altLang} = pageContext

  const seo = portfolio.seo
    ? {
        title: portfolio?.title || site?.title,
        description: portfolio?.seo?.description || site.description,
        image: portfolio?.seo?.image,
        altLang: pageContext.altLang,
        altLangUrl: pageContext?.altLangUrl || "en",
        locale: pageContext.locale
      }
    : {}

  if (!portfolio) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://locsalhost:3333 and add "About" page data and restart the development server.'
    )
  }
  const photos = processGallery(gallery)
  const previewPhotos = processPreviewGallery(gallery)

  const backString = locale === "en" ? "Back" : "Atras"

  return (
    <SeoContext.Provider value={seo}>
      <LayoutContainer locale={locale} altLangUrl={altLang}>
        <SEO
          title={seo.title}
          lang={locale}
          description={seo.description}
          altLang={altLang}
          altLangUrl={seo.altLangUrl}
          {...pageContext}
        />

        <Container>
          <h1 className={responsiveTitle1}>{portfolio.title}</h1>
          <Link
            className={backLink}
            // sx={{color: "#2F4141"}}
            // to={`/${locale}/portfolio`}>
            to={`/portfolio`}
          >
            &larr; {backString}
          </Link>

          <Gallery
            photos={photos}
            // direction={"column"}
            // columns={3}
            targetRowHeight={400}
            onClick={openLightbox}
          />

          <ModalGateway>
            {console.log("previewPhotos", previewPhotos)}
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={previewPhotos.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
          {/* {galleryRender} */}
        </Container>
      </LayoutContainer>
    </SeoContext.Provider>
  )
}

export default localize(PortfolioTemplate)

export const query = graphql`
  query PortfolioTemplateQuery($id: String!) {
    portfolio: sanityPortfolio(id: {eq: $id}) {
      id
      slug {
        _type
        es {
          current
        }
        en {
          current
        }
        _type
      }
      gallery {
        _key
        _type
        alt
        tags {
          title
        }

        asset {
          altText
          fixed {
            aspectRatio
            height
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
            width
          }
          fluid {
            srcWebp
            srcSetWebp
            srcSet
            src
            sizes
            base64
            aspectRatio
          }
        }
      }
      id
      mainImage {
        _key
        _type
        # alt
        asset {
          altText
        }
      }
      title {
        _type

        en
        es
      }
      seo: openGraph {
        title
        image {
          asset {
            _id
          }
        }
        description
      }
    }
  }
`